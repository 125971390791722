<template>
    <div>
        <b-card no-body class="mb-2 p-1">
            <div class="row">
                <b-col cols="3">
                    <router-link
                        class="btn btn-success"
                        v-if="filters.contractor && orderToContractorActIds.length"
                        :to="{
                            name: 'AdmissionActCreate',
                            params: {
                                order_to_contractor_act_ids: orderToContractorActIds,
                                contractor_id: filters.contractor.id
                            }
                        }"
                    >
                        Принять
                    </router-link>
                </b-col>
                <b-col cols="9" class="text-right">
                    <b-button variant="primary" @click="applyFilters" class="mr-1">
                        <b-icon-arrow-repeat font-scale="1.2"/>
                    </b-button>
                    <b-button v-b-toggle.filters variant="light"><b-icon-filter/>Фильтры</b-button>
                    <small v-if="filtersExists">
                        <b-link @click.prevent="resetFilters">(Сбросить)</b-link>
                    </small>
                </b-col>
            </div>
            <b-collapse id="filters">
                <b-card-body>
                    <b-row>
                        <b-col md="4">
                            <b-form-group description="Дата (от)">
                                <input type="date" class="form-control" v-model="filters.dateFrom"/>
                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <b-form-group description="Дата (до)">
                                <input type="date" class="form-control" v-model="filters.dateTo"/>
                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <b-form-group description="Поставщик">
                                <contractor-input v-model="filters.contractor"></contractor-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <div class="row">
                        <b-col md="3" lg="3">
                            <b-form-checkbox v-model="filters.showReceived" switch>
                                Показать принятые
                            </b-form-checkbox>
                        </b-col>
                        <b-col md="4">
                            <b-form-checkbox v-model="filters.only_orders_of_brand_kitchen" switch>
                                Показать заказы бренд кухни
                            </b-form-checkbox>
                        </b-col>
                    </div>
                </b-card-body>
            </b-collapse>
        </b-card>

        <b-table
            responsive
            :items="items"
            :fields="fields"
            head-variant="dark"

            :sort-by.sync="orderBy.by"
            :sort-desc.sync="orderBy.desc"
            no-sort-reset
            no-local-sorting
            @row-dblclicked="towardsActForm"
        >
            <template #cell(select)="data">
                <b-form-checkbox
                    :disabled="data.item.canceled || filters.contractor === null"
                    v-model="orderToContractorActIds"
                    :value="data.item.id"
                />
            </template>

            <template #head(state)="data">
                <b-icon-circle-fill/>
            </template>

            <template #cell(state)="data">
                <b-icon-circle-fill :class="data.item.state"/>
            </template>

            <template #cell(actions)="data">
                <b-button size="sm" variant="info" :to="routerActForm( data.item.id )">
                    <b-icon-eye-fill/>
                </b-button>
            </template>
        </b-table>

        <b-row>
            <b-col md="6">
                <span>
                    Показано с {{ meta.current_page * meta.per_page - meta.per_page + 1 }}
                    по {{ meta.current_page * meta.per_page - meta.per_page + items.length }}
                    из {{ meta.total_rows }} записей
                </span>
            </b-col>
            <b-col md="6">
                <b-pagination
                    v-model="meta.current_page"
                    :total-rows="meta.total_rows"
                    :per-page="meta.per_page"
                    @input="applyFilters"
                    class="justify-content-end"
                />
            </b-col>
        </b-row>
    </div>
</template>

<script>
import moment                                                  from 'moment';
import map                                                     from 'lodash/map';
import forEach                                                 from 'lodash/forEach';
import ContractorInput                                         from '../_common/ContractorInput';
import lsfMixin                                                from '../../utils/localStorageFilterMixin';
import {ORDER_TO_CONTRACTOR_ACTS_ENDPOINT}                     from "@utils/endpoints";
import {STATUS_ENOUGH, STATUS_NOT_ENOUGH, STATUS_NO_ADMISSION} from "./OrderToContractorActStatuses";

const RECORD_PRE_PAGE = 25;

const FILTERS_PATTERN = {
    dateTo: null,
    dateFrom: null,
    contractor: null,
    showReceived: true,
    only_orders_of_brand_kitchen: false,
};

export default {
    mixins: [lsfMixin],
    components: {ContractorInput},
    name: "OrderToContractorActList",
    data() {
        return {
            orderBy: {by: 'incoming_date', desc: true},

            items: [],
            fields: [
                {key: 'select', label: ''},
                {key: 'state', label: ''},
                {key: 'incoming_date', label: 'Дата', sortable: true},
                {key: 'nomenclature', label: 'Номенклатура'},
                {
                    key: 'is_order_of_brand_kitchen',
                    label: 'Заказ бренд кухня',
                    formatter(value) {
                        return value ? 'Да' : 'Нет';
                    },
                },
                {key: 'contractor', label: 'Контрагент'},
                {key: 'number', label: 'Номер'},
                {key: 'actions', label: 'Действия'}
            ],

            orderToContractorActIds: [],
            meta: {
                total_rows: 0,
                current_page: 1,
                per_page: 25,
            },
        };
    },
    computed: {
        preparedConditions() {
            let conditions = {
                filters: {}
            };

            forEach(this.getFilters, function (filter, key) {
                if (key === 'showReceived') {
                    conditions.filters[key] = filter ? 1 : 0;

                    return true;
                }

                if (filter) {
                    if (key === 'contractor') {
                        conditions.filters[key] = filter.id;
                    } else {
                        conditions.filters[key] = filter;
                    }

                    return true;
                }
            });

            conditions['sort'] = this.orderBy['by'];
            conditions['sortDirection'] = this.orderBy.desc ? 'desc' : 'asc';

            conditions['appends'] = ['status'];

            conditions['with'] = [
                'contractor',
                'orderToContractorItems.nomenclature'
            ];

            conditions['limit'] = RECORD_PRE_PAGE;
            conditions.page = this.meta.current_page;

            return conditions;
        },
    },
    watch: {
        orderBy: {
            deep: true,
            handler() {
                this.applyFilters();
            }
        },
        filters: {
            deep: true,
            handler(value) {
                this.meta.current_page = 1;
                this.watchFilters(value);
            }
        }
    },
    methods: {
        routerActForm(id) {
            return {name: 'OrderToContractorActForm', params: {id: id}};
        },
        towardsActForm(record) {
            this.$router.push(this.routerActForm(record.id));
        },
        generationLink(number) {
            return number;
        },
        prepareItems(orderToContractorActs) {
            let preparedOrderToContractorActs = [];
            orderToContractorActs.forEach(function (orderToContractorAct) {
                let state = '';
                switch (orderToContractorAct.status) {
                    case STATUS_NO_ADMISSION:
                        state = 'text-dark';
                        break;
                    case STATUS_NOT_ENOUGH:
                        state = 'text-danger';
                        break;
                    case STATUS_ENOUGH:
                        state = 'text-success';
                        break;
                }

                preparedOrderToContractorActs.push({
                    state: state,
                    id: orderToContractorAct.id,
                    is_order_of_brand_kitchen: orderToContractorAct.is_order_of_brand_kitchen,
                    nomenclature: map(orderToContractorAct.orderToContractorItems, 'nomenclature.name').join(', '),
                    incoming_date: moment(orderToContractorAct.incoming_date).format('DD.MM.YYYY'),
                    contractor: orderToContractorAct.contractor.name,
                    number: orderToContractorAct.incoming_number,
                    canceled: orderToContractorAct.canceled,
                    _rowVariant: orderToContractorAct.canceled ? 'danger' : null
                });
            });

            return preparedOrderToContractorActs;
        },
        applyFilters() {
            let params = this.preparedConditions;

            this.$http
                .get(ORDER_TO_CONTRACTOR_ACTS_ENDPOINT, {params})
                .then(response => {
                    this.meta = {
                        total_rows: response.data.data.total,
                        current_page: response.data.data.current_page,
                        per_page: response.data.data.per_page,
                    }

                    this.items = this.prepareItems(response.data.data.data);
                });
        },
    },
    created() {
        this.initFilters(this, FILTERS_PATTERN);
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.act-table >>> td {
    vertical-align: middle;
}
</style>
